import styled from "styled-components";
import BackgroundImage from "./assets/background.jpg"

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background-image: url(${BackgroundImage});
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */

	filter: blur(4px);
  	-webkit-filter: blur(4px);
`;

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const CoffeeShopText = styled.div`
	color: white;
	text-shadow: 0px 0px 20px #000000;
	font-family: EBGaramond-Bold;

	// for small screens
   	font-size: 40px;

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
   		font-size: 40px;
	}

`; // EB Garamond Bold (81pt)

const SoundsText = styled.div`
	color: white;
	text-shadow: 0px 0px 20px #000000;
	font-family: Pacifico-Regular;
	font-weight: bold;
	margin-top: -0.35em;

	// for small screens
	font-size: 60px;

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
   		font-size: 60px;
	}

`; // Pacifico Bold (134 pt)

const PlayerMobile = styled.iframe`
	border-radius: 12px;

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		display: none;
	}

`;
const PlayerDesktop = styled.iframe`
	border-radius: 12px;
	width: 50%;
	
	display: none;

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		display: flex;
	}

`;

const LinksContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;

	width: 30%; // for mobile

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		width: 10% // for desktop
	}
`;

const PlatformLogoContainer = styled.a`
	display: inline-block; 
	overflow: hidden; 
	border-radius: 13px; 

	// for mobile
	width: 30px; 
	height: 30px;
	margin-bottom: 10px;

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		width: 50px; 
		height: 50px;
		margin-bottom: 30px;
	}

`;
const PlatformLogo = styled.img`
	border-radius: 13px; 

	// for mobile
	width: 30px; 
	height: 30px;
	margin-bottom: 10px;


	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		width: 50px; 
		height: 50px;
		margin-bottom: 30px;
	}
`;


export {
	Background,
	Container,
	CoffeeShopText,
	SoundsText,
	PlayerMobile,
	PlayerDesktop,
	PlatformLogoContainer,
	PlatformLogo,
	LinksContainer
};