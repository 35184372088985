import React from 'react';
import SpotifyLogo from './assets/spotify.png'
import YoutubeLogo from './assets/youtube.png'
import {
  Background,
  Container,
  CoffeeShopText,
  SoundsText,
  PlayerMobile,
  PlayerDesktop,
  PlatformLogoContainer,
  PlatformLogo,
  LinksContainer
} from "./App.styles"
import "./App.css"

function App() {
  return (
    <>
      <Background />
      <Container>
        <CoffeeShopText>Coffee Shop</CoffeeShopText>
        <SoundsText>Sounds</SoundsText>
        <LinksContainer>
          {/*Apple Logo*/}
          <PlatformLogoContainer
            href="https://podcasts.apple.com/us/podcast/coffee-shop-sounds/id1631055618?itsct=podcast_box_appicon&amp;itscg=30200&amp;ls=1">
            <PlatformLogo  
              src="https://tools.applemediaservices.com/api/badges/app-icon-podcasts/standard/en-us?size=83x83&amp;releaseDate=1664060460&h=1f1ecc9e1e3e1bf32e8619cd91fb8481" 
              alt="App Icon Apple Podcasts" 
            />
          </PlatformLogoContainer>

          {/*Spotify Logo*/}
          <PlatformLogoContainer
            href="https://open.spotify.com/show/355KJjD8AKEG2ykaFyxuMP?si=a3fbfbd4b08642c4">
            <PlatformLogo  
              src={SpotifyLogo}
              alt="App Icon Spotify Podcasts" 
            />
          </PlatformLogoContainer>

        {/*Youtube Logo*/}
          <PlatformLogoContainer
            href="https://www.youtube.com/channel/UCRfGPk3v54SfxiJllUCEVDg">
            <PlatformLogo  
              src={YoutubeLogo}
              alt="App Icon Youtube Channel" 
            />
          </PlatformLogoContainer>
        </LinksContainer>
        
        <PlayerMobile
          src="https://open.spotify.com/embed/show/355KJjD8AKEG2ykaFyxuMP?utm_source=generator&theme=0" 
          width="75%" 
          height="152" 
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        />
        <PlayerDesktop 
          src="https://embed.podcasts.apple.com/us/podcast/coffee-shop-sounds/id1631055618?itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" 
          height="450px" 
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" 
          allow="autoplay *; encrypted-media *; clipboard-write"
        />
      </Container>
    </>
  );
}

export default App;
